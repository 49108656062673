

let model = {};

// dữ liệu form
model.dataForm = {
    "id": undefined, // optional - Mã tự sinh
    "name": undefined, // required - Tên Table Screen
    "type": undefined, // required, enum(probe, bras) - Loại Table Screen
};

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 10,
  offset: 0,
  sort: "name",
  defaultSort: ["name", "ascending"],
  filters: "",
  dataSearch: {
      value: {
          name: "",
          type: "",
      },
      operator: {
        name: ":regex_i:",
        type: ":regex_i:",
      },
  },
};
export default model;
