<template>
  <div>
    <div class="d-flex mb-2">
      <span>Cấu hình màu biểu đồ</span>
      <div @click="addConfig" class="btn-add d-flex">
        <i class="ri-add-circle-line" style="margin-right: 5px"></i>Thêm
      </div>
    </div>

    <div class="config-group" v-if="configs.value?.length > 0">
      <b-row class="m-2">
        <b-col
          md="12"
          v-for="(config, index) in configs.value"
          :key="index"
          class="config-item"
        >
          <div class="color-configs">
            <div>
              <span>Gradient</span>
              <el-switch
                v-model="config.gradient"
                @change="changeOptionGradient(index)"
              />
            </div>
            <div>
              <span>Màu line (hoặc border)</span>
              <el-color-picker v-model="config.line_color" />
            </div>
            <div>
              <span>{{
                config.gradient === false ? "Màu nền" : "Màu đầu"
              }}</span>
              <el-color-picker v-model="config.bg_color.color" />
            </div>
            <div v-if="config.gradient">
              <span>Màu cuối</span>
              <el-color-picker v-model="config.bg_color.to_color" />
            </div>

            <el-tooltip effect="light" content="Xóa" placement="top">
              <el-button
                class="box-item"
                type="danger"
                size="small"
                @click="removeConfig(index)"
                ><i class="ri-close-line"></i></el-button
            ></el-tooltip>
          </div>
        </b-col>
      </b-row>

      <div class="confirm-configs">
        <el-button
          @click="acceptConfig"
          size="small"
          type="success"
          :loading="loadingBtn"
          >{{ $t("t-confirm") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, reactive, watch, ref } from "vue";
const emit = defineEmits([]);
const props = defineProps({
  colorConfigIn: Array,
});
const configs = reactive({ value: props.colorConfigIn });
const loadingBtn = ref(false);
const addConfig = () => {
  configs.value.push({
    gradient: true,
    bg_color: {
      color: "",
      to_color: "",
    },
    line_color: "",
  });
};

const changeOptionGradient = (index) => {
  configs.value[index].bg_color.to_color = "";
  emit("colorConfigOut", configs);
};

const acceptConfig = () => {
  loadingBtn.value = true;
  emit("colorConfigOut", configs);
  console.log("configs", configs);
  setTimeout(() => {
    loadingBtn.value = false;
  }, 1000);
};
const removeConfig = (index) => {
  configs.value.splice(index, 1);
  emit("colorConfigOut", configs);
};
watch(
  () => props.colorConfigIn,
  (newData) => {
    newData.forEach((item) => {
      !item.bg_color.to_color
        ? (item.gradient = false)
        : (item.gradient = true);
    });
    configs.value = newData;
    console.log("configs.value", configs.value);
  }
);
</script>
<style scoped>
.btn-add {
  background-color: #409eff;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px 7px;
  margin-left: 50px;
  transition: 0.2s;
}
.btn-add:hover {
  background-color: rgba(64, 160, 255, 0.849);
}
.config-group {
  border-radius: 3px;
  border: thin solid #dcdcdc;
  margin: 0 0 15px 0;
  padding: 0 0 7px 0;
}
.config-group span {
  margin-right: 10px;
}
.confirm-configs {
  text-align: center;
  margin-top: 10px;
}
.config-item {
  margin-bottom: 10px;
  border: thin dashed #cdcdcd;
  border-radius: 3px;
  padding: 10px 0;
}
.config-item button {
  margin: 0 0 5px 7px;
}
.color-configs {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  padding: 0 15px;
}
.box-item {
  position: absolute;
  right: 10px;
  top: 14px;
}
</style>
