
import LanguageService from "../../service/LanguageService";

let model = {};

// dữ liệu form
model.dataCardItems = {
  id: undefined, // optional - Mã tự sinh
  card_id: undefined, // optional, ref: card - Mã card
  type: undefined, // required, enum(chart-bar-vert-single, chart-bar-vert-multi, chart-bar-hori-single, chart-bar-hori-multi, chart-line-single, chart-line-multi, chart-line-area-single, chart-line-area-multi, chart-pie, table, group-text, err-network-2segments, err-network-3segments, stats-network-multi-serv, time-line) - Loại card item
  title: undefined, // required - Tiêu đề
  // optional - Các thuộc tính chung của card item
  general: {
    // optional - Cấu hình header của card item
    header: {
      is_hidden: undefined,
      color: undefined, // optional - Màu
      backgroundColor: undefined, // optional - Màu nền
    },
  },
  x: undefined, // required - Tọa độ x
  y: undefined, // required - Tọa độ y
  width: undefined, // required - Chiều ngang
  height: undefined, // required - Chiều dọc
  table_config_id: undefined, // required, ref: table_config - Mã của table_config
  // optional - các thuộc tính của table nếu type là table
  table: {
    hasNo: undefined, // required - Có cột số thứ tự hay không
    isPaging: undefined, // required - Có phân trang hay không
    format: [
      {
        field_name: undefined, // required - Tên trường
        title_name: undefined, // optional - Tên cột
        visible: undefined, // optional - Có hiển thị không
        type: undefined, // optional, enum(none, rate, progress) - Kiểu cột
        width: undefined,
        font_size: undefined, // optional - Kích thước font chữ
        color: undefined, // optional - Màu font chữ
        bold: undefined, // optional - Bôi đậm
        italic: undefined, // optional - Chữ nghiêng
        underline: undefined, // optional - Gạch chân
        align: undefined, // optional, enum(left, center, right, justify) - Căn chỉnh vị trí
        formatString: undefined, // optional - Định dạng chuỗi
        cellLink: {
          valueColumn: undefined, // optional - Tên cột chứa dữ liệu so sánh. Là tên cột trong api trả về; đứng sau as trong câu sql
            links: [{
              values: [], // optional, minLength: 1, array items: optional - Danh sách các giá trị của valueColumn để hiển thị link này
              formatString: undefined, // optional - Chuỗi định dạng để sinh ra nội dung cell
            }],
            defaultLink: {
              formatString: undefined,
            },
        },
        // optional - Cấu hình lọc
        filter: {
          is_allowed: undefined, // optional - Cho phép lọc không
          input_type: undefined, // optional, enum(input, select, datepicker, tag_input) - Kiểu input
          is_suggestion: undefined, // optional - Đối với loại tag input. Có cho phép hiển thị gợi ý không?
          suggest_values: [], // optional - Danh sách lựa chọn (đối với kiểu select hoặc tag_input)
        },
        // optional - Cấu hình sắp xếp
        sort: {
          is_allowed: undefined, // optional - Cho phép sắp xếp không
          type: undefined, // optional, enum(String, Date, Number) - Loại dữ liệu sort
        },
      },
    ], // optional - Danh sách thông tin định dạng các cột, được push vào đây
  },
  // optional - các thuộc tính của chart nếu type là chart-
  // optional - các thuộc tính của chart nếu type là chart-*
  chart: {
    // optional - Tiêu đề
    title: {
      show: undefined, // optional - Hiển thị hay không
      text: undefined, // optional - Nội dung tiêu đề
      // optional - Định dạng text
      textStyle: {
        fontSize: undefined, // optional - Kích thước font chữ
        color: undefined, // optional - Màu chữ
      },
    },
    // optional - Tiêu đề
    legend: {
      show: undefined, // optional - Hiển thị hay không
      orient: "horizontal", // optional, enum(horizontal, vertical) - Mặc định horizontal
      align: {
        horizontal: "center", // Mặc định center
        vertical: "bottom", // Mặc định bottom
      },
    },
    // optional - Các cấu hình cho trục hoành
    x_axis: {
      min: undefined, // optional - Chiều ngang tối thiểu của biểu đồ
      max: undefined, // optional - Chiều ngang tối đa của biểu đồ
      // optional - Cấu hình cho label trục hoành
      axisLabel: {
        rotate: undefined, // optional, min: -90, max: 90 - Độ nghiêng
      },
      // optional - Cấu hình split line
      splitLine: {
        // optional - Cấu hình line style
        lineStyle: {
          color: undefined, // optional - Màu
          type: undefined, // optional, enum(solid, dashed, dotted) - Loại
          width: undefined, // optional - Chiều dài
        },
      },
    },
    // optional - Các cấu hình cho trục tung
    y_axis: {
      min: undefined, // optional - Chiều cao tối thiểu của biểu đồ
      max: undefined, // optional - Chiều cao tối đa của biểu đồ
      // optional - Cấu hình cho label trục tung
      axisLabel: {
        rotate: undefined, // optional, min: -90, max: 90 - Độ nghiêng
      },
      // optional - Cấu hình split line
      splitLine: {
        // optional - Cấu hình line style
        lineStyle: {
          color: undefined, // optional - Màu
          type: undefined, // optional, enum(solid, dashed, dotted) - Loại
          width: undefined, // optional - Chiều dài
        },
      },
    },
    data_serials: [],
    color: [], // optional - Danh sách màu cho chart
    // optional - Cấu hình style cho item
    borderRadiusType: "none",
    // optional - Cấu hình style cho area
    smooth: undefined, // optional - Cấu hình line smooth cho các chart có dạng line
  },
  // optional - Các thuộc tính của network nếu type là network_
  network: {
    service_ids: [], // optional - Danh sách mã service
  },
  sql: undefined, // optional - câu truy vấn để lấy dữ liệu
  sql_table_formats: [], // optional - câu truy vấn con trong biến @table
  disableService: false,
  // optional - Các thuộc tính của header
  settings: {
    add_start_days: undefined, // optional
    add_end_days: undefined, // optional
  },
};

model.formatAlign = [
  {
    value: "left",
    label: LanguageService.lang.align_left,
  },
  {
    value: "right",
    label: LanguageService.lang.align_right,
  },
  {
    value: "center",
    label: LanguageService.lang.align_center,
  },
  {
    value: "justify",
    label: LanguageService.lang.align_justify,
  },
];

model.typeColumns = [
  {
    value: "none",
    label: LanguageService.lang.none_type_columns,
  },
  {
    value: "rate",
    label: LanguageService.lang.rate_type_columns,
  },
  {
    value: "progress",
    label: LanguageService.lang.progress_type_columns,
  },
];

model.visibleColumns = [
  {
    value: true,
    label: LanguageService.lang.unvisible_columns,
  },
  {
    value: false,
    label: LanguageService.lang.visible_columns,
  },
];

model.typeFilter = [
  {
    value: "input",
    label: "Input",
  },
  {
    value: "select",
    label: "Select",
  },
  {
    value: "datepicker",
    label: "Datepicker",
  },
  {
    value: "tag_input",
    label: "Tag input",
  },
  {
    value: "number",
    label: "Number",
  },
];

model.typeSort = [
  {
    value: "String",
    label: "String",
  },
  {
    value: "Date",
    label: "Date",
  },
  {
    value: "Number",
    label: "Number",
  },
];
model.formatLegend = [
  {
    value: "horizontal",
    label: "Ngang",
  },
  {
    value: "vertical",
    label: "Dọc",
  },
];
model.horizontalLegend = [
  // mặc định là center
  {
    value: "left",
    label: "Left",
  },
  {
    value: "center",
    label: "Center",
  },
  {
    value: "right",
    label: "Right",
  },
];
model.verticalLegend = [
  // mặc định là bottom
  {
    value: "top",
    label: "Top",
  },
  {
    value: "middle",
    label: "Middle",
  },
  {
    value: "bottom",
    label: "Bottom",
  },
];

model.typeBorder = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "small",
    label: "Small",
  },
  {
    value: "large",
    label: "Large",
  },
];
model.listDashboards = [
  {
    value: "main",
    label: "Probe",
  },
  {
    value: "service",
    label: "Dịch vụ",
  },
  {
    value: "customer",
    label: "Khách hàng",
  },
  {
    value: "timespan",
    label: "Theo khung giờ",
  },
  {
    value: "session",
    label: "Session",
  },
];

model.colorDefault = [
  "#407BFF",
  "#7489FF",
  "#A4A6FE",
  "#A1E3CB",
  "#50E7B0",
  "#A1F6FC",
  "#F1F37C",
  "#FFDB5E",
  "#F7CF95",
  "#FFB69E",
  "#FFA869",
  "#FA7DFD",
];
model.colorDefaultGradientBarChart = [
  ["#407BFF", "#A6C1FF"],
  ["#95A4FC", "#D8D8FF"],
  ["#C6C7F8", "#E5E6FF"],
  ["#A1E3CB", "#D9FBEF"],
  ["#BAEDBD", "#E4FFE6"],
  ["#C9F5F8", "#E8FEFF"],
  ["#EFF0C1", "#FEFFE4"],
  ["#FFE999", "#FFF3C8"],
  ["#ECDBC1", "#FFF5E7"],
  ["#FFD7CB", "#FFE9E2"],
  ["#FFC499", "#FFE0CA"],
  ["#F4B2F5", "#FED9FF"],
];
model.colorDefaultGradientAreaChart = [
  ["#407BFF", "#A6C1FF", "#FFFFFF"],
  ["#7489FF", "#95A4FC", "#FFFFFF"],
  ["#A4A6FE", "#C6C7F8", "#FFFFFF"],
  ["#A1E3CB", "#BAEDBD", "#FFFFFF"],
  ["#50E7B0", "#A1E3CB)", "#FFFFFF"],
  ["#A1F6FC", "#C9F5F8", "#FFFFFF"],
  ["#F1F37C", "#EFF0C1", "#FFFFFF"],
  ["#FFDB5E", "#FFE999", "#FFFFFF"],
  ["#F7CF95", "#ECDBC1", "#FFFFFF"],
  ["#FFB69E", "#FFD7CB", "#FFFFFF"],
  ["#FFA869", "#FFC499", "#FFFFFF"],
  ["#FA7DFD", "#F4B2F5", "#FFFFFF"],
];
model.formatColumnsTable = {
  code: undefined, // optional - Mã trường
  field_name: undefined, // required - Tên trường
  title_name: undefined, // optional - Tên cột
  // optional - Cấu hình icon
  icon: {
    visible: undefined, // optional - Cho phép hiển thị không
    file_id: undefined, // optional, ref: icon - Id file icon
  },
  visible: undefined, // optional - Có hiển thị không
  type: undefined, // optional, enum(none, rate, progress) - Kiểu cột
  default_value: undefined, // optional - Giá trị mặc định
  width: undefined, // optional - Kích thước cột
  font_size: undefined, // optional - Kích thước font chữ
  color: undefined, // optional - Màu font chữ
  bold: undefined, // optional - Bôi đậm
  italic: undefined, // optional - Chữ nghiêng
  underline: undefined, // optional - Gạch chân
  align: undefined, // optional, enum(left, center, right, justify) - Căn chỉnh vị trí
  formatString: undefined, // optional - Định dạng chuỗi
  cellLink: {
    valueColumn: undefined, // optional - Tên cột chứa dữ liệu so sánh. Là tên cột trong api trả về; đứng sau as trong câu sql
    links: [{
      values: [], // optional, minLength: 1, array items: optional - Danh sách các giá trị của valueColumn để hiển thị link này
      formatString: undefined, // optional - Chuỗi định dạng để sinh ra nội dung cell
    }],
    defaultLink: {
      formatString: undefined,
    },
  },
  // optional - Cấu hình lọc
  filter: {
    is_allowed: undefined, // optional - Cho phép lọc không
    input_type: undefined, // optional, enum(input, select, datepicker, tag_input, number) - Kiểu input
    is_suggestion: undefined, // optional - Đối với loại tag input. Có cho phép hiển thị gợi ý không?
    suggest_values: [], // optional - Danh sách lựa chọn (đối với kiểu select hoặc tag_input)
  },
  // optional - Cấu hình sắp xếp
  sort: {
    is_allowed: undefined, // optional - Cho phép sắp xếp không
    type: undefined, // optional, enum(String, Date, Number) - Loại dữ liệu sort
    default: undefined, // optional - Kiểu sắp xếp mặc định
  },
};
export default model;
